export default [
	{
		path: '/',
		name: 'Login',
		component: () =>
            import('@/views/Login'),
        meta: {
            requireAuth: false,
            redirectHomeIfLogged: true
        }
    },
    {
		path: '/modules',
		name: 'Modules',
		component: () =>
            import('@/views/Modules'),
        meta: {
            requireAuth: true,
            redirectHomeIfLogged: false
        }
	},
    {
		path: '/user-profile',
		name: 'UserProfile',
		component: () =>
            import('@/views/UserProfile'),
        meta: {
            requireAuth: true,
            redirectHomeIfLogged: false
        }
	}
]