"use strict";

import en from 'vuetify/es5/locale/en'

export default {
    ...en,
    signIn: 'Login',
    signInBtn: 'Sign In',
    forgotPass: 'Recover password',
    forgotPassBtn: 'Send',
    forgotPassLink: 'Forgot password?',
    returnToLogin: 'Return to login',
    selectLocale: 'Select a language',
    emailLoginLabel: 'E-mail',
    passLoginLabel: 'Password',
    register: 'Register',
    createAccount: 'Create account',
    name: 'Name',
    password: 'Password',
    changePassword: 'Change password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    cancel: 'Cancel',
    help: 'Help',
    logout: 'Logout',
    userInformation: 'User information',
    userInformationxs: 'Profile',
    change: 'Change',
    add: 'Add',
    remove: 'Remove',
    primaryEmail: 'Primary e-mail',
    addAlternateEmail: 'Add alternate e-mail',
    addAlternateEmailxs: 'Alternate e-mail',
    phone: 'Phone',
    primaryPhone: 'Primary phone',
    addAlternatePhone: 'Add alternate phone',
    addAlternatePhonexs: 'Alt. ph.',
    extension: 'Extension',
    smsCellphone: 'Fill SMS cellphone',
    smsCellphonexs: 'SMS cellphone',
    chooseRegisteredPhone: 'Or choose registered phone',
    passwordExpirationDate: 'Password expiration date',
    lastUpdate: 'Last update',
    saveChanges: 'Save changes',
    save: 'Save',
};