<template>
	<v-app id="app" :style="appStyles">
		<transition name="fadeRouter" mode="out-in">
			<v-main>
				<v-responsive
					min-height="100vh"
					max-height="100vh"
					style="overflow:auto"
				>
					<transition name="fadeRouter" mode="out-in">
						<router-view />
					</transition>
				</v-responsive>
			</v-main>
		</transition>
	</v-app>
</template>

<script>
// import configs from '../src/configs.js'
import { version } from '../package.json'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
	name: 'App',

	data() {
		return {
			appVersion: version,
		}
	},

	computed: {
		...mapGetters('auth', ['getHash', 'getTokenExp']),
		...mapGetters('theme', ['getTheme']),
		...mapGetters(['getSystem']),

		bgUrl() {
			if (this.getSystem.system === 'bauducco') {
				return `url(img/${this.getSystem.system}-bg.png)`
			} else {
				return `linear-gradient(90deg, ${this.$vuetify.theme.themes.light.primary} 0%, ${this.$vuetify.theme.themes.light.secondary} 100%)`
			}
		},

		appStyles() {
			return {
				'background': `${this.$vuetify.theme.themes.light.primary}`,
				'background-image': `${this.bgUrl}`,
				'background-position': 'center',
				'background-repeat': 'no-repeat',
				'background-size': 'cover',
				'-webkit-background-size': 'cover',
				'-moz-background-size': 'cover',
				'-o-background-size': 'cover',
			}
		}
	},

	methods: {
		...mapActions('auth', ['logout', 'hashAuthenticate', 'getHashAuth']),
		...mapMutations('auth', ['setEnableSessionExpiredDialog', 'setSessionExpiredDialog', 'resetState', 'setLoadingAuth', 'setReloadDialog']),

		setTheme() {
			const colours = require(`@/plugins/themes/${this.getTheme}`)
			this.$vuetify.theme.themes.light = colours.default
		},

		async isValidHash(payload) {
			if (!payload.hash) {
				return this.logout()
			}

			try {
				const res = await this.$http.post(this.$ipUser + 'user/validate-hash', { ...payload })
				if (res) {
					if (res.data.isValidHash === null) {
						this.resetState()
						return this.$router.push('/')
					}

					return !!res.data.isValidHash
				}
			} catch (err) {
				this.$fnError(err)
				return false
			}
		},

		isValidToken(tokenExp) {
			let now = Math.floor(Date.now() / 1000)
            return now - tokenExp <= 0
		},

		removeHashFromURL() {
			window.history.replaceState({}, document.title, "" + "/modules")
		},

		successLogin() {
			this.setEnableSessionExpiredDialog(true)
			this.setLoadingAuth(false)
			if (this.$route.path === '/') {
				return this.$router.push('/modules')
			} else {
				return this.removeHashFromURL()
			}
		},

		async verifyAppVersion() {
			let serverVersion = ''

			let url = `https://fan.znaptech.com/version.json`
			
			fetch(url)
				.then((response) => response.json())
				.then((data) => {
					serverVersion = data.version
					if (this.appVersion !== serverVersion) {
						console.log('serverVersion: ', serverVersion)
						console.log('appVersion: ', this.appVersion)
						return this.setReloadDialog(true)
					} else {
						return this.setReloadDialog(false)
					}
				})
				.catch((err) => {
					this.$fnError(err)
					this.setReloadDialog(false)
				})
		},
	},

	async created() {
		this.setLoadingAuth(true)
		this.setTheme()

		this.verifyAppVersion()

		this.setEnableSessionExpiredDialog(false)
		this.setSessionExpiredDialog(false)
		let url = new URL(window.location.href)
		let hash = null
		let hashUrl = false
		let hashStore = false
		let isValidSessionToken = false
		let isValidHash = false
		let isLogout = false

		hashUrl = !!url.searchParams.get("token")

		isLogout = !!url.searchParams.get("logout")

		if (hashUrl) {
			hash = url.searchParams.get("token")
		} else {
			hash = this.getHash

			if (hash) {
				hashStore = true
			}
		}

		if (isLogout) {
			this.logout()
		}

		if (hash) {
			isValidHash = await this.isValidHash({ hash })
		}

		let tokenExp = this.getTokenExp
		if (tokenExp) {
			isValidSessionToken = this.isValidToken(tokenExp)
		}

		if (!hashUrl && !hashStore && !isValidSessionToken && !isValidHash) {
			this.resetState()
			return this.$router.push('/')
		}

		if (hashUrl && isValidHash) {
			const auth = await this.hashAuthenticate(hash)
			if (auth === 'success') {
				if (this.$route.path !== '/modules') {
					this.setInvalidHashDialog(false)
					this.setEnableSessionExpiredDialog(true)
				}
				return this.successLogin()
			} else {
				let err = auth
				this.$fnError(err)
			}
		}

		if (hashStore && isValidHash && !isValidSessionToken) {
			return this.setSessionExpiredDialog(true)
		}

		if ((hashUrl || hashStore) && !isValidHash && !isValidSessionToken) {
			this.resetState()
			return this.$router.push('/')
		}

		if (!isValidHash && isValidSessionToken) {
			const auth = await this.getHashAuth()
			if (auth === 'success') {
				let route = url.searchParams.get("route")
				return this.successLogin(route)
			} else {
				return
			}
		}
		
		this.setLoadingAuth(false)
		this.setEnableSessionExpiredDialog(true)
	},
}
</script>

<style>
html {
	overflow-y: auto !important;
	font-family: 'Panton';
}

/* ::-webkit-scrollbar-track{
	display: none;
}

::-webkit-scrollbar{
	width: 8px;
	z-index:200;
	height: 5px;
}

::-webkit-scrollbar-thumb{
	background-color: rgba(0,0,0,0.3);
	border-radius: 10px;
} */

.fadeRouter-enter {
	opacity: 0;
}

.fadeRouter-enter-active {
	transition: opacity .28s ease;
}

.fadeRouter-leave-active {
	transition: opacity .28s ease;
	opacity: 0;
}

@font-face {
	font-family: 'Panton';
	font-weight: 100;
	src: url('./assets/fonts/Panton-Light.otf')
}

@font-face {
	font-family: 'Panton';
	font-weight: 400;
	src: url('./assets/fonts/Panton.otf')
}

@font-face {
	font-family: 'Panton';
	font-weight: 500;
	src: url('./assets/fonts/Panton-Bold.otf')
}

@font-face {
	font-family: 'Panton';
	font-weight: 900;
	src: url('./assets/fonts/Panton-Black.otf')
}
</style>
