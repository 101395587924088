import axios from 'axios'
import Vue from 'vue'
import store from '@/store'

const instance = createInstance()

function createInstance() {
    return axios.create({
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

instance.interceptors.request.use((request) => {
    if (store.state.auth.token) {
        request.headers.common.Authorization = `Bearer ${store.state.auth.token}`
    }

    if (request.data && typeof request.data === 'object') {
        let dataDefault = {
            language: store.state.system.language === 'pt_BR' ? 'pt' : store.state.system.language,
            clientId: store.state.system.clientId,
            module: store.state.system.module,
            system: store.state.system.system,
        }

        if (request.data instanceof FormData) {
            request.headers.common['Content-Type'] = 'multipart/form-data'

            request.data.append('language', dataDefault.language)
            request.data.append('clientId', dataDefault.clientId)
            request.data.append('module', dataDefault.module)
            request.data.append('system', dataDefault.system)
        } else {
            request.data = {
                ...dataDefault,
                ...request.data,
            }
        }

    }

    store.commit('issue/setLastRequest', request)
    return request
}, (error) => {
    return Promise.reject(error)
})

Vue.prototype.$http = instance

export default {
    getInstance: () => instance
}