"use strict";

import pt from 'vuetify/es5/locale/pt'

export default {
    ...pt,
    signIn: 'Login',
    signInBtn: 'Entrar',
    forgotPass: 'Recuperar a senha',
    forgotPassBtn: 'Enviar',
    forgotPassLink: 'Esqueceu a senha?',
    returnToLogin: 'Retornar para login',
    selectLocale: 'Selecionar o idioma',
    emailLoginLabel: 'E-mail',
    passLoginLabel: 'Senha',
    register: 'Registrar',
    createAccount: 'Criar conta',
    name: 'Nome',
    password: 'Senha',
    changePassword: 'Alterar senha',
    oldPassword: 'Senha antiga',
    newPassword: 'Nova senha',
    cancel: 'Cancelar',
    help: 'Ajuda',
    logout: 'Sair',
    userInformation: 'Informações do usuário',
    userInformationxs: 'Perfil',
    change: 'Alterar',
    add: 'Adicionar',
    remove: 'Remover',
    primaryEmail: 'E-mail principal',
    addAlternateEmail: 'Adicionar e-mail alternativo',
    addAlternateEmailxs: 'E-mail alternativo',
    phone: 'Telefone',
    primaryPhone: 'Telefone principal',
    addAlternatePhone: 'Adicionar telefone alternativo',
    addAlternatePhonexs: 'Tel alternativo',
    extension: 'Ramal',
    smsCellphone: 'Preencha um celular para receber SMS',
    smsCellphonexs: 'Celular para SMS',
    chooseRegisteredPhone: 'Ou escolha um telefone cadastrado',
    passwordExpirationDate: 'Data de expiração da senha',
    lastUpdate: 'Última atualização',
    saveChanges: 'Salvar alterações',
    save: 'Salvar',
};