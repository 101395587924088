import Vue from 'vue'
import jwt_decode from "jwt-decode"
import configs from '@/configs'

const getDefaultState = () => {
    return {
        token: '',
        iat: '',
        exp: null,
        hash: '',
        user: {},
        id: null,
        status: '',
        modules: [],
        system: '',
        sessionExpiredDialog: false,
        enableSessionExpiredDialog: false,
        loadingAuth: false,
        reloadDialog: false,
    }
}

const state = getDefaultState()

const getters = {
    getToken: state => state.token,
    getTokenExp: state => state.exp,
    getHash: state => state.hash,
    isAuthenticated: state => !!state.token,
    getStatus: state => state.status,
    getModules: state => state.modules,
    getUser: state => state.user,
    getSessionExpiredDialog: state => state.sessionExpiredDialog,
    getEnableSessionExpiredDialog: state => state.enableSessionExpiredDialog,
    isLoadingAuth: state => state.loadingAuth,
}

const mutations = {
    setAuth(state, token) {
        state.token = token
        state.iat = jwt_decode(token).iat
        state.exp = jwt_decode(token).exp
    },

    setUser(state, payload) {
        state.user = payload
    },

    setSessionExpiredDialog(state, payload) {
        state.sessionExpiredDialog = payload
    },

    setEnableSessionExpiredDialog(state, payload) {
        state.enableSessionExpiredDialog = payload
    },

    setToken(state, token) {
        state.token = token
    },

    setTokenIat(state, iat) {
        state.iat = iat
    },

    setTokenExp(state, exp) {
        state.exp = exp
    },

    setHash(state, hash) {
        state.hash = hash
    },

    setModules(state, modules) {
        state.modules = modules
    },

    setInvalidHashDialog(state, payload) {
        state.invalidHashDialog = payload
    },

    resetState (state) {
        Object.assign(state, getDefaultState())
    },

    setLoadingAuth(state, payload) {
        state.loadingAuth = payload
    },

    setReloadDialog(state, payload) {
        state.reloadDialog = payload
    },
}

const actions = {
    async authenticate({ state, commit, dispatch }, user) {
        try {
            state.status = 'loading'

            const payload = {
                email: user.email,
                password: user.password,
                login_service: 'znap'
            }

            const res = await Vue.prototype.$http.post(Vue.prototype.$ipUser + 'user/login', payload)
            if (res) {
                commit('setHash', res.data.hash)
                dispatch('setClientModules')
                state.id = res.data.id[0]
                dispatch('setUser', state.id)
                commit('setAuth', res.data.token)
            }

            state.status = 'success'
            return res
        } catch (err) {
            state.status = 'error'
            throw err
        }
    },

    async hashAuthenticate({ state, commit, dispatch }, hash) {
        commit('setHash', hash)
        
        try {
            const res = await Vue.prototype.$http.post(Vue.prototype.$ipUser + 'user/hash/login', { hash })
            if (res) {
                dispatch('setClientModules')
                state.id = res.data.id[0]
                dispatch('setUser', state.id)
                commit('setAuth', res.data.token)
                return 'success'
            }
        } catch (err) {
            console.log('error: ', err)
            return err
        }
    },

    async getHashAuth({ state, commit, dispatch }, payload) {
        try {
            const res = await Vue.prototype.$http.post(Vue.prototype.$ipUser + 'user/get-hash', { ...payload })
            if (res.data.hash) {
                commit('setHash', res.data.hash)
                dispatch('setClientModules')
                state.id = res.data.id[0]
                dispatch('setUser', state.id)

                commit('setAuth', res.data.token)
                return 'success'
            } else {
                dispatch('logout')
            }
        } catch (err) {
            console.log('error: ', err)
            return err
        }
    },

    async setUser({ commit }, userId) {
        try {
            const res = await Vue.prototype.$http.post(Vue.prototype.$ipUser + `user/list/${userId}`, { })
			if (res) {
				const user = res.data.rows[0]
                commit('setUser', user)
			}
        } catch (err) {
            this.$fnError(err)
        }
    },

    async setToken({ commit }, token) {
        commit('setToken', token)
        commit('setTokenIat', jwt_decode(token).iat)
        commit('setTokenExp', jwt_decode(token).exp)

        return token
    },

    async setClientModules({ commit }) {
        const res = await Vue.prototype.$http.post(Vue.prototype.$ipClient + 'client-module/list/client', { })
        if (res) {
            let modules = res.data.rows.filter(r => r.access_denied === 0)
				
            let portalIndex = modules.findIndex(m => m.id_module === 1)
            modules.splice(portalIndex, 1)

            commit('setModules', modules)
        }
    },

    async logout({ commit, dispatch }) {
        try {
            await Vue.prototype.$http.patch(Vue.prototype.$ipUser + 'user/update-hash', { hash: null })
        } catch (err) {
            console.log(err)
        } finally {
            commit('resetState')
            dispatch('redirectToLogin')
        }
    },

    redirectToLogin() {
        let url = null
        if (window.location.href.indexOf(':80') == -1)	{
            url = `https://${configs.system}${configs.env === 'homol' ? '.homol' : ''}.znaptech.com/`
        } else {
            url = `http://${configs.system}.znaptech:${configs.port}/`
        }

        return window.location.href = url
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}