import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createPersistedState from "vuex-persistedstate"
import configs from '../configs'
import SecureLS from "secure-ls"
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
	modules,
	state() {
        return {
            system: {
                language: configs.language,
                clientId: configs.id,
                module: configs.module,
                system: configs.system
            }
        }
    },
	mutations:{
        setLanguage(state, payload) {
            // Vue.prototype.$vuetify.framework.lang.current
            state.system.language = payload
        },
	},
	getters:{
        getSystem(state){
            return state.system
        }
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ]
})